

.titulo{
    background-image: url(../components//img/inovacaotecnologica.jpg);
    height: 15rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.titulo h1{
    color: white;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
    font-size: 3rem;
    padding-top: 5rem;
    margin-left: 2rem;

}


.vmo h1{
    padding-left: 2rem;
    margin-top: 2rem;
}
.vmo div{
    width: 100%;
    text-align: center;
    display: flex;
    margin-top: 1rem;
    
    
}


.vmo img{
    width: 90%;
    margin: auto;
}


.texto{
    display: inline-block;
    flex-direction: column;
    padding-right: 2rem;
    margin-top: 2rem;
    
}

.texto p{
    text-align: justify;
    color: #818181;
}
.vmo h2{
    text-align: left;
    color: #4f4f4f;
}
.vmo ul.lista{
    margin: 0;
    padding-left: 1rem;
}
.vmo ul.lista li{
    list-style: square;
    text-align: left;
    padding-left: 0rem;
    margin: 0;
}


@media (max-width: 700px){
    .titulo{
        background-image: url(../components//img/inovacaotecnologicamobile.jpg);
    }
    
    .vmo div{
       display: inline-block;
      width: 100%;
        
    }
    .vmo p{
        padding: 0;
        margin: 0;
        width: 95%;
        margin:auto;
        padding-left: 1.2rem;
        color: #818181;
        text-align: center;
        

    }
    
    .textop{
       padding: 0;
        margin: auto;
        width: 90%;
        
    }
    .titulo h1{
        font-size: 2rem;
        margin: 0;
        color: white;
        text-align: left;
    }

    .vmo ul.lista{
        margin: auto;
        padding-left: 2rem;
        width: 95%;
    }
    .vmo ul.lista li{
        list-style: square;
     
        padding-left: 0rem;
        margin: 0;
    }
    .texto h2{
        text-align: center;
        font-size: 1.5rem;
        width: 95%;
        margin-top: 1rem;
        color: #4f4f4f;
    }
    
}
.card{
    width: 18rem;
    height: 21rem;
    border-style: solid;
    border-width: 1px;
    border-color: #8b8b8b50;
    display: flex;
    margin: 1rem;
}

.card img{
    width: 100%;
    
}

.card p{
    text-align: center;
    font-weight: bold;
    color: #383838;
}


.nav{
    margin: 0 0 -1rem 0;
    padding: 0;
}
.nav ul {
    display: flex;
    margin: 0;
    padding: 0;
}
 
.nav ul li{
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
    font-size: 0.8rem;
}

li{
    color:#8b8b8b;
}
a{
    color: #4d4d4d;
}

a:hover{
    text-decoration: none;
    color: #333333;
    
}

.nav{
    user-select: none;
}
.container{
    margin-top: 4rem;
}
.contato{
    width: 90%;
    margin: auto;
    background-color: #f5f3f3;
    padding: 2rem;
    border-radius: 1rem;
    border-style: solid;
    border-width: 0.1px;
    border-color: rgba(0, 0, 0, 0.166);
}

.container button{
    background-color: #525252;
    border-style: none;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    transition: 0.2s;

}

.container button:hover{
    background-color: #232323;
    color: white;
    

}

.successMessage{
    height: 250px;
}

.successMessage h3{
    text-align: center;
    font-weight: bolder;
    color: #6b6b6b;
}
.successMessage h2{
    text-align: center;
    font-weight: bolder;
    color: #525252;
}


.container{
    margin: auto;
    text-align: center;
   
}


.intro div{
    margin-top: 1rem;
    height: 20rem;
    background-position: 0px 40%;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 2rem;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
    
    
}

.intro div h1{
    text-align: left;
    padding-left: 3rem;
    color: white;
    width: 40%;
    padding-top: 5rem;
    font-size: 5rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.742);
}

.intro div h2{
    text-align: left;
    font-weight: bold;
    padding-left: 3rem;
    color: white;
    font-size: 2.5rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
    width: 70%;
    
}
.container p{
    color: #8b8b8b;
    width: 75%;
    text-align: justify;
    margin: auto;
    margin-bottom: 1rem;
    
}
.intro p{
   padding: 2rem;
   border-style: solid;
   border-width: 1px;
   border-color: #8b8b8b;
   border-radius: 10px;
   background-color: #444444;
   color: white;
   width: 90%;
   font-size: 1.2rem;
   box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.599);
   
}


.paraQuemE{
    display: flex;
    width: 90%;
    margin: 5rem auto;
    padding-right: 2rem;
    border-right-style: solid;
    border-width: 1px;
    border-color: #4545453e;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.173);
   
    
}

.paraQuemE h2{
    color: #454545;
}

.paraQuemE img{
    width: 30rem;
    height: auto;
   
}

.paraQuemE p{
    padding-left: 2rem ;
    margin: auto;
    
    

}


.bloco2{
    display: flex;
    border-left-style: solid;
    padding-left: 1rem;
    border-width: 1px;
    border-color: #4545453e;
    width: 90%;
    height: 20rem;
    margin: 6rem auto;
    margin: auto;
    
}

.bloco2 h2{
    color: #454545;
    padding-top: 0rem;
}

.bloco2 p{
    padding-right: 2rem;
    height: auto;
    margin: auto;
}

.bloco2 img{
    width: 30rem;
}

.container button{
    border-width: 1px;
    border-color: #454545;
    background-color: #444444;
    color:white;
    padding: 0.5rem 5rem;
    font-size: 2rem;
    border-radius: 3rem;
    font-weight: bold;
    cursor: pointer;
    margin-top: 1rem;
    transition: 0.5s;
}

.container button:hover{
    background-color: black;
}


.NaoEcontrou{
    font-size: 1.5rem;
    padding-top: 2rem;
    font-weight: bold;
}

iframe{
    margin-top: 3rem;
}

@media (max-width:700px){
    .container {
        margin: auto;
        text-align: center;
    }
    
    .intro div {
        margin-top: 1rem;
        height: 20rem;
        background-position: 0px 40%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 2rem;
        box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.5);
        
    }
   
    .intro div h1 {
        text-align: center; /* Alterado para centralizar o texto em telas menores */
        padding: 2rem; /* Adicionado espaço ao redor do texto */
        color: white;
        font-size: 4rem; /* Reduzido o tamanho da fonte para telas menores */
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.742);
    }
    
    .intro div h2 {
        text-align: left; /* Alterado para centralizar o texto em telas menores */
        font-weight: bold;
        color: white;
        width: 100%;
        font-size: 2rem; /* Reduzido o tamanho da fonte para telas menores */
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
       
    }
    
    .container p {
        color: #8b8b8b;
        width: 98%; /* Aumentado a largura para melhor legibilidade em telas menores */
        text-align: left;
        margin: auto;
        margin-bottom: 1rem;
    }
    
    .intro p {
        padding: 1rem; /* Reduzido o preenchimento para telas menores */
        border-style: solid;
        border-width: 1px;
        border-color: #8b8b8b;
        border-radius: 10px;
        background-color: #444444;
        color: white;
        width: 98%; /* Aumentado a largura para melhor legibilidade em telas menores */
        font-size: 1rem; /* Reduzido o tamanho da fonte para telas menores */
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.599);
    }
    
    .paraQuemE {
        flex-direction: column; /* Alterado para empilhar os elementos em telas menores */
        width: 90%;
        margin: 3rem auto; /* Reduzido a margem para telas menores */
        padding: 0;
        align-items: center; /* Centralizado os itens em telas menores */
        border: none; /* Removido a borda direita em telas menores */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.173);
    }
    
    .paraQuemE img {
        width: 100%;
        height: auto;
    }
    
    .paraQuemE p {
        width: 98%;
        padding: 0.8rem;
        margin: 0;
        text-align: center; /* Centralizado o texto em telas menores */
    }
    
    .bloco2 {
        flex-direction: column; /* Alterado para empilhar os elementos em telas menores */
        border-left: none; /* Removido a borda esquerda em telas menores */
        padding-left: 0; /* Removido o preenchimento à esquerda em telas menores */
        width: 90%;
        height: auto;
        margin: 6rem auto;
    }
    
    .bloco2 h2 {
        color: #454545;
        padding-top: 1rem; /* Aumentado o espaço superior em telas menores */
        text-align: center; /* Centralizado o texto em telas menores */
    }
    
    .bloco2 p {
        padding: 1rem; /* Adicionado preenchimento para melhor legibilidade em telas menores */
        text-align: center; /* Centralizado o texto em telas menores */
        width: 98%;
    }
    
    .bloco2 img {
        width: 100%;
    }
    
    .container button {
        width: 80%; /* Aumentado a largura para melhor legibilidade em telas menores */
        font-size: 1.5rem; /* Reduzido o tamanho da fonte para telas menores */
        margin-top: 1rem;
    }
    
    
  
}
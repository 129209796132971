
.sobreNos article{
    width: 100%;
    margin: auto;
    color: #8b8b8b;
    text-align: justify;
}

.titulo{
    background-image: url(../components//img/sobrenos.jpeg);
    height: 15rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

} 

.titulo h1{
    color: white;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
    font-size: 3rem;
    padding-top: 5rem;
    padding-left: 2rem;
    

}


.equipe {
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.equipe img{
    width: 40%;
}

.equipe p{
    padding: 1.5rem;
}
.sobreNos{
    margin-top: 1rem;
}

@media (max-width: 700px){
    .sobreNos{
        margin-top: 1rem;
    }
    .equipe{
        display: block;
    }
    .equipe img{
        width: 100%;
        margin: auto;
    }
    .sobreNos article{
        text-align: center;
    }
}
.sobreNos{
    background-color: white;
    display: flex;
    color: #818181;
    padding-top: 3rem;
    padding-bottom: 3rem;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 90vw;
    
}
.fotoEquipe{
    width: 100%;
}
.sobrenosImg{
    width: 45%;
    margin: auto;
    margin-bottom: 2em;
}

.sobrenosTexto{
    
    width: 45%;
    margin: auto;
    font-weight: 500;
}

.sobrenosImg h1{
    font-weight: bolder;
    text-align: center;
    margin-bottom: 1em;
    

}

.sobrenosTexto a{
    text-decoration: none;
    color: #676767;
    font-size: 1.5rem;
    font-weight: bold;
    border-style: solid;
    border-width: 1px;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    transition: 0.5s;
}


.sobrenosTexto a:hover{
    text-decoration: none;
    color: white;
    background-color: #676767;
}





@media ( max-width: 600px){
    .sobreNos{
        display: block;
        padding-top: 5rem;
    padding-bottom: 3rem;
       
    }

    .fotoEquipe{
        width: 100%;
    }
    .sobrenosImg{
        width: 100%;
    }
    
    .sobrenosTexto{
        padding-top: 1rem;
        width: 90%;
        margin: auto;
    
    }
    

}
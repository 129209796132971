.container{
   margin: auto;
   width: 90%;
   margin-bottom: 4rem;
   margin-top: 4rem;
}
.container h1{
    color: #676767;
    font-weight: bold;
}

.container p{
    color: #676767;
    
}
.container img{
    width: 30%;
}

.container div{
    display: flex;
}

.container div div{
    display: block;
    flex-direction: column;
    text-align: justify;
    padding: 2rem;
}

.container div div a{
    text-decoration: none;
    color: #676767;
    font-size: 1.5rem;
    font-weight: bold;
    border-style: solid;
    border-width: 1px;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    transition: 0.5s;
}

.container div div a:hover{
    text-decoration: none;
    color: white;
    background-color: #676767;
}


@media (max-width: 700px){
   .container h1{
    text-align: center;
   }
    .container div{
        display: block;
    }
    .container img{
        width: 100%;
    }
    .container{
        width: 100%;
    }
    .container div div p{
        padding: 0;
        width: 100%;
         
    }
    
}
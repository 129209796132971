

.apresentacao{
    background-image: url(../img/main/grafico.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 90%;
    height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
}

.h1Apresentacao{
    font-size: 3rem;
    color: white;
    width: 60%;
    margin: auto;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.219);
    text-align: center;
}

@media (max-width:600px) {
    .apresentacao{
        background-position-y: -10rem;
        background-position-x: -5rem;
        
    }


    .h1Apresentacao{
        font-size: 2.3rem;
        width: 90%;
    }
    
}


.titulo{
    background-image: url(../components//img/saladeaula.jpg);
    height: 15rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}
.mbaCursos{
   margin-top: 1rem;
}
.mbaCursos h1{
    margin-left: 3rem;
    margin-top: 1rem;
}

.titulo h1{
    color: white;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.617);
    font-size: 3rem;
    padding-top: 5rem;
    margin-left: 2rem;
    text-align: left;

}


.mbaCursos p{
    width: 80%;
    margin-bottom: 2rem;
    margin: auto;
    padding: 2rem 0;
    text-align: justify;
}

.mbaCursos div{
    display: flex;
   
   

}

.cards{
    justify-content: center;
}

.link{
    color: #8b8b8b;
}

.link:hover{
    text-decoration: none;
    color: #3b3b3b;
}

@media (max-width: 798px){
   .mbasCursos{
    padding: 0;
   }
    .mbaCursos h1{
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 3rem;
    }
    .mbaCursos div{
        display: block;
        
        justify-content: center;
        margin: auto;
        margin-top: 1rem;
    
    } 
        
    .mbaCursos p{
        text-align: center;
    }
    iframe{
        width: 90%;
        height: 40rem;
    }
    
}